@tailwind  base;
@tailwind  components;

@tailwind utilities;

body{
    font-family: "Veto W01 Regular",Helvetica,Arial,sans-serif;
}

.header-height {
    height: 120px;
}

@media(min-width: 768px){
    .header-height {
        height: 168px;
    }
}


/* needed because of video js, vjs style sheet magically does not work any longer, this is a quick fix */
.video-js button.vjs-big-play-button {
    left: calc(50% - 45px) !important;
    top: 50%;
    transform: translateY(-25px) !important;
}

.download-icon {
    display: inline-block;
    vertical-align: baseline;
    margin-right: 6px;
}

@media(min-width: 1600px){
    .footer--special-breakpoint{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

h1, .h1{
    @apply text-primary text-4xl my-4;
}

h2, .h2{
    @apply text-primary text-3xl my-4;
}

h3, .h3{
    @apply text-black font-b text-2xl my-4;
}
h4, .h4{
    @apply text-black font-b text-xl my-4;
}
.h4-primary{
    @apply text-primary font-b text-xl my-4;
}
h5, .h5{
    @apply text-black font-b text-lg my-4;
}
.subtitle{
    @apply text-black font-b text-lg;
}
.btn{
    @apply inline-block rounded-lg font-b px-6 py-4 my-4 dark-light-gradient shadow;
}
strong {
    @apply font-b;
}
ul{
    @apply p-2 list-disc ml-4;
}
ol{
    @apply list-decimal my-2 ml-6;
}
p + p {
    @apply mt-2;
}
.btn:hover, .btn:active, .btn:focus{
    @apply opacity-75;
}
.span6{
    @apply w-full p-4 pl-0;
}
@screen md{
    .span6{
        @apply w-1/2;
    }
}
main .main-content img{
    @apply rounded;
}
.row-fluid{
    @apply flex flex-wrap;
}
input[type=text], textarea{
    @apply bg-white border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal;
}
input[type=text]:focus, textarea:focus{
    @apply outline-none shadow-outline 
}
.dark-light-gradient{
    background: linear-gradient(90deg, #4B973E 0%, #B7D159 100%);
}
.dark-light-gradient-hover{
    background: linear-gradient(90deg #306128 0%, #A3BA50 100%);
}
@screen md{
    .home-teaser-container > .teaser-element{
        max-width: 30%;
    }
    .teaser-img{
        max-height: 10rem;
        object-fit: contain;
    }
}
.teaser-element:hover, .teaser-element:active, .teaser-element:focus {
    box-shadow: 0 4px 0 0 #E5ECDB;
    background-color: rgba(229,236,219,0.35);
}
ul.breadcrumbs {
    @apply list-none;
}
ul.breadcrumbs > li{
    @apply inline-block;
}
ul.breadcrumbs li:not(:last-child):after{
    content: '›';
    display: inline-block;
    margin-left: 0;
    margin-right: 0.4em;
    color:black;
}

.collapsible_item_title_addon {
    transition: all 0.3s ease;
}
.collapsible_item_title_addon span:after {
    content: '›';
    display: block;
    width: 100%;
    transition: 0.35s;
    transform: rotate(90deg);
    font-size:2rem;
    margin-top:-0.3rem;
}
.collapsible_item_title[aria-expanded='true'] .collapsible_item_title_addon span:after{
    transform: rotate(270deg);
}
.collapsible_item_body[aria-hidden='true'] {
    height: 0;
    visibility: hidden;
    display:none;
    opacity: 0;
}
.collapsible_item_body[aria-hidden='false']{
    height: auto;
    visibility: visible;
    opacity: 1;
    padding: 0 1rem 2rem 2rem;
    transition: visibility 0.3s, opacity 0.3s linear, height 0.1s linear;
}

#cookie-alert {
    @apply overflow-y-scroll h-full block fixed inset-x-0 bottom-0 max-w-5xl mx-auto w-full p-4 z-50;
}
@screen sm{
    #cookie-alert {
        @apply h-auto overflow-y-auto;
    }
}
.cookie-checkboxes .cookie-checkboxes-element label form--checkbox{
    min-width:32px;
}
#cookie-toggle-info:checked ~ .cookie-analyse-info {
    @apply h-full;
}
#cookie-toggle-info:checked ~ label .cookie-mehrInfo{
    @apply hidden;
}
#cookie-toggle-info:checked ~ label .cookie-wenigerInfo { 
    @apply inline-block;
}
.teaser-element .teaser-text > a > p{
    color:black;
}
.teaser-element:hover{
    text-decoration: none;
}
.infobox-right{
    margin-left:auto;
}
.main-header{
    background-color: rgba(216, 216, 216, 0.25);
}
@screen lg{
    .infobox-left{
        margin-left:-5rem;
    }
    .infobox-right{
        margin-right:-5rem;
    }
}

@media(max-width: 767px){
    .responsivetable {
        @apply overflow-x-scroll overflow-y-visible;
    }
}
.responsivetable{
    @apply border border-black rounded-lg;
}
table.contenttable{
    @apply p-4
}
.contenttable th, .contenttable td{
    @apply  border border-black rounded;
}
.contenttable th, .contenttable td{
    @apply p-4;
}
.contenttable th{
    @apply text-left
}
.contenttable tbody tr {
    @apply my-2;
}
.contenttable tbody tr:first-of-type td:first-of-type, .contenttable tbody tr:first-of-type th:first-of-type{
    @apply rounded-tl-lg;
}
.contenttable tbody tr:first-of-type td:nth-of-type(2), .contenttable tbody tr:first-of-type th:nth-of-type(2){
    @apply rounded-tr-lg;
}
.contenttable tbody tr:last-of-type{
    @apply rounded-b;
}
.contenttable tbody tr:nth-child(2n-1){
    @apply bg-primarymint;
}